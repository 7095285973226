
<template>
  <div class="text-center">
<v-dialog
        v-model="dialog"
        max-width="450px"
        min-height="400px"
        class="round"
      >
        <v-card class="round">
          <div class="content-dialog">
              <span class="text-dialog">¡Gracias por contactarte con nosotros!</span>
              <img  class="btn-ok mt-6" src ="../../assets/320x480/Boton_Ok.png" @click="setDialog"/>
          </div>
                   
      
        </v-card>
      </v-dialog>
  



  </div>
</template>

<script>
export default {
    name:'dialog-info',
    props:['dialog','setDialog']
}
</script>
<style scoped>
.content-dialog{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  padding-top: 35px;
  padding-bottom: 35px;
}
.btn-ok{
  height: 45px;
  width: auto;
  object-fit: cover;
}
.round{
  border-radius: 14px;
}
.text-dialog{
  font-family: "Open Sans";
  text-align: center;
}
</style>