import axios from 'axios'

const  USER="clienteyipi";
const PASS="passwordyipi";
  
const requestApi = axios.create({
    baseURL: 'https://yipi.mx/api'
})


   export async function  postApiResource (endpoint, params)  {
        return await requestApi.post(endpoint,params,{
            auth: {
              username: USER,
              password: PASS
            }
          }) ;
      }


