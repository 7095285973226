<template>
    <v-form  @submit="onSubmit" :class='sizeClass ? sizeClass :"padding-form" '
       ref="form">
       <DialogTerminos
      :dialog="terminos"
      :setDialog="setTerminos"
      :setCheckbox="setCheckbox"
    />
    <div class="center-margin" >
      <p   :class="titleClass ? titleClass : 'parrafo-title-2 mt-5'">{{title+" " }}<span class="green-weight">{{spanTitle}}</span >{{titleEnd}}</p>
      <p class="parrafo-title-2">{{subtitle}}</p>
    </div>

        <v-container>
          <v-row>
            <v-col  :cols="field.cols"  :sm="field.cols" class="padding-5" v-for="field in fields" :key="field.index">
              <v-text-field
                outlined
                color="var(--green-light-01)"
                :label="field.label"
                :rules="field.rules"
                v-model="field.vmodel"
           
                :prepend-inner-icon="field.icon ? field.icon : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="padding-5">
              <v-layout>
                <v-checkbox
                  class="shrink pt-3 mt-0"
                  off-icon="$checkboxNo"
                  on-icon="$checkboxSi"
                  v-model="checkbox"
                   :rules="checkRules"
                >
                        <template v-slot:label>
                          <div>
                            Acepto los
                              <template >
                                <a  @click.stop="setTerminos2"
                                 class="title-small"
                                style="color: #00EC9E">
                                  Términos y condiciones
                                </a>
                              </template>
                          </div>
                        </template>
                </v-checkbox>
              </v-layout>
            </v-col>
            <v-col cols="12" sm="12" class="center">
              <img @click="onSubmit" class="btn" src="../../assets/768x1024/Boton_Enviar.png" />     
            </v-col>
          </v-row>
        </v-container>
      </v-form>

</template>
<script>
import DialogTerminos from "./DialogTerminos.vue";
import {checkValidation} from"@/utils/validation";
export default {
    
    data: () => ({
          terminos: false,
          checkbox:false,
          checkRules: checkValidation
    }),
    props:[
        'fields','sizeClass','title','spanTitle','titleEnd','subtitle','titleClass'
    ],
    methods:{
        onSubmit(e){
         e.preventDefault();
        if( this.$refs.form.validate() ){
                this.$emit('setData');
                this.$refs.form.reset();
        }

        },
          setTerminos() {
      this.terminos = !this.terminos;
   
    },
    setTerminos2(e) {
    e.preventDefault();       
      this.terminos = !this.terminos;
   
    },
      setCheckbox(value) {
      this.checkbox = value;
    },
    },
    components:{
           DialogTerminos,
    }
}
</script>
<style >
.center-margin{
  margin-left:25px;
  margin-right: 25px;
  margin-top:0px;
  text-align: center;
  
}
.btn{
  width: auto;
  height: 35px;
  object-fit: contain;
 cursor: pointer;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.parrafo-title-2{
   font-family: Open Sans;
  font-weight: 100;
  font-size: .8em;
}
.padding-5 {
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-test{
  background-color: white;
  border: 3px solid #686868;
  max-width: 480px;
  min-height: 570px;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
}

.text-btn-custom{
  font-family:BebasNeue;
  font-size: 19px !important; 
}
.title-3{
  margin-top: 10px;
  padding-bottom:5px;
  font-size:1.1em;
  font-family: Open Sans;
  text-align: center;
  font-weight: bold;
  color: #545252;
}
.title-form {

font-family: Open Sans;
font-size: 1.1rem;
color:#545252;
font-weight: bold;
}
.padding-form{
margin-top: 15px;
margin-left: 0px;
margin-right: 0px;
margin-bottom: 0px;
}

@media  (min-width:760px) {
.parrafo-title-2{
   font-family: Open Sans;
    font-weight: 100;
  font-size: 1.3em;
}
.title-3{
  margin-top: 30px;
  padding-bottom:10px;
  font-size:1.6em;
  font-family: Open Sans;
  text-align: center;
  font-weight: 900;
  color: #545252;
}

}
@media (min-width: 1024px) {
  .title-form {
line-height: .5;
font-family: Open Sans;
font-size: 1.4rem;
color:#545252;
font-weight: 900;
}
.padding-form{
margin-top: 15px;
margin-left: 0px;
margin-right: 0px;
margin-bottom: 0px;
}
  .title-3{
  margin-top: 30px;
  padding-bottom:10px;
  font-size:1.8em;
  font-family: Open Sans;
  text-align: center;
  font-weight: 900;
  color: #545252;
}
  .parrafo-title-2{
   font-family: Open Sans;
  line-height:1;
  font-size: 1.1em;
}
  .form-test{
  
  margin-left: 70px;
  }
  .padding-form{
margin-top: 40px;
margin-left: 90px;
margin-right: 90px;
}
.btn{
  width: auto;
  height: 47px;
  object-fit: contain;
 cursor: pointer;
}
}
</style>